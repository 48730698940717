@import '../App.scss';

.CardButton {
  height: 120px;
  width: 45%;
  margin-bottom: 30px;
  position: relative;

  &-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }

  &-flipped {
    .CardButton-inner {
      transform: rotateY(180deg);
    }
  }

  &-front,
  &-back {
    border-radius: 20px;
    position: absolute;
    @extend %xy-centered;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  &-front {
    border: 1px solid $colorPrimary;
    color: $colorPrimary;
  }

  &-back {
    border: 1px solid $colorSecondary;
    color: $colorSecondary;
    transform: rotateY(180deg);
  }

  &-blank {
    background: inherit;
    box-shadow: none;
  }

  .icon-flip {
    position: absolute;
    right: 6%;
    bottom: 10%;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (min-width: 600px) {
  .CardButton {
    width: 30%;
  }
}
