@import '../App.scss';

.AddDeckPopup {
  .popup {
    input {
      border: 1px solid $colorBorder;
      height: 60px;
      padding: 20px;
      margin-bottom: 50px;
    }

    p {
      font-size: 24px;
      font-weight: bold;
      color: $colorSecondary;
    }

    button {
      width: 160px;
      height: 50px;
      border-radius: 30px;
      background: $colorPrimary;
    }
  }
}
