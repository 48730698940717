@import '../App.scss';

.DeckButton {
  height: 120px;
  background: linear-gradient(to right, $colorPrimary, $colorSecondary);
  -webkit-box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  width: 45%;
  margin-bottom: 30px;
  color: white;
  font-size: 18px;
  font-weight: 600;

  &-blank {
    background: inherit;
    box-shadow: none;
  }

  &-card {
    background: inherit;
    box-shadow: none;
    border: 1px solid $colorPrimary;
    color: $colorPrimary;
    font-size: 16px;
    font-weight: 400;
  }

  .icon-switch {
    position: absolute;
    right: 6%;
    bottom: 10%;
  }
}

.decks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (min-width: 600px) {
  .DeckButton {
    width: 30%;
  }
}
