@import '../App.scss';

.Auth {
  width: 100%;
  height: 100vh;

  h5 {
    margin-bottom: 20px;
  }

  .Login {
    padding: 10% 5%;
    @extend %xy-centered;
    flex-direction: column;
  }

  button {
    width: 100%;
    height: 50px;
    border-radius: 50px;
    margin-top: 20px;
  }

  .button {
    &-login {
      background: $colorPrimary;
    }

    &-register {
      border: 2px solid $colorSecondary;
      color: $colorSecondary;
    }

    &-submit {
      width: 60%;
      background: $colorSecondary;
    }
  }

  .AuthInput {
    width: 100%;
  }
}

@media only screen and (min-width: 480px) {
  .Auth {
    button {
      width: 160px;
    }

    .button {
      &-submit {
        width: 160px;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .Auth {
    button {
      width: 160px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .Auth {
    .AuthInput {
      width: 60%;
    }

    .RegisterPopup {
      .AuthInput {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (min-height: 768px) {
  .Auth {
    background: url('../assets/loginBg.svg') no-repeat;
    background-position: right;
    background-size: auto 100%;

    .Login {
      display: block;
      width: 50%;
      padding: 15% 0 0 5%;
      text-align: left !important;

      button {
        margin-top: 50px;
        margin-right: 20px;
      }
    }
  }
}
