@import '../App.scss';

.AuthInput {
  margin-bottom: 20px;

  .input {
    display: grid;
    grid-template-columns: 15% 85%;
    border: 2px solid $colorPrimary;
    height: 60px;
    color: $colorPrimary;

    &-icon {
      @extend %xy-centered;
    }

    input {
      padding-right: 5%;
    }
  }

  .error {
    color: red;
    text-align: right;
    margin-top: 10px;
  }
}
