@import '../App.scss';

.Header {
  border-bottom: 0.5px solid rgba($colorBorder, 0.5);

  .logo {
    width: 60%;
  }

  .navbar-collapse {
    margin-top: 3%;
  }

  .navbar-toggler {
    padding: 0;
    color: $colorBorder;
  }

  .nav-link {
    font-weight: 600;
  }

  button:hover {
    color: $colorSecondary;
  }
}

@media only screen and (min-width: 575px) {
  .Header {
    border: none;

    .LinkWithIcon {
      .icon {
        display: none;
      }
    }

    .logo {
      width: 100%;
    }

    .navbar-collapse {
      margin-top: 0;
    }
  }
}
