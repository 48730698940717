@import '../App.scss';

.DeckEdit {
  h6 {
    position: sticky;
    top: 0;
    font-weight: bolder;
    margin-bottom: 5%;
    font-size: 120%;
    color: $colorPrimary;
  }

  .cards-edit {
    padding-top: 1%;
    position: absolute;
    width: 90%;
    height: 70%;
    overflow-y: auto;
    overflow-x: hidden;

    #card-description {
      display: none;
    }

    .card-edit {
      margin: 0 2% 5% 1%;
      -webkit-box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.16);
      box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.16);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 5%;

      input {
        &[name='key'] {
          color: $colorPrimary;
          margin-bottom: 10px;
        }

        &[name='value'] {
          margin-top: 10px;
          color: $colorSecondary;
        }
      }

      .input-value {
        display: flex;
        flex-direction: column;
        width: 100%;

        input {
          width: 100%;
        }

        span {
          color: $colorSecondary;
        }
      }
    }

    span {
      align-self: flex-end;
    }
  }

  .buttons {
    position: absolute;
    bottom: 8%;
    right: 5%;

    button {
      border-radius: 50px;
      width: 100px;
      height: 40px;
    }

    .button {
      &-cancel {
        background: $colorSecondary;
        margin-right: 5px;
      }
      &-done {
        background: $colorPrimary;
      }
    }
  }

  .button-delete {
    position: absolute;
    bottom: 1%;
    right: 5%;
  }

  #icon-add {
    border: 1px solid $colorPrimary;
    width: 45%;
    padding: 10px;
    @extend %xy-centered;
  }
}

@media only screen and (min-height: 768px) {
  .DeckEdit {
    .cards-edit {
      height: 80%;
    }
  }
}

@media only screen and (min-width: 600px) {
  .DeckEdit {
    .cards-edit {
      #card-description {
        display: flex;
        input {
          font-weight: bolder;
          font-size: 110%;
          height: auto;
          padding: 0;
          &[name='front'] {
            color: $colorPrimary;
          }

          &[name='back'] {
            color: $colorSecondary;
          }
        }
      }

      .card-edit {
        box-shadow: none;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 3%;
        padding: 0;

        .horizontal-line {
          display: none;
        }

        input {
          width: 45%;
          padding-left: 8px;
          height: 70px;

          &[name='key'] {
            margin: 0;
            border: 1px solid $colorPrimary;
          }

          &[name='value'] {
            margin: 0;
            border: 1px solid $colorSecondary;
          }
        }

        .input-value {
          width: 45%;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .DeckEdit {
    .buttons {
      bottom: 0;
    }

    .button-delete {
      position: absolute;
      bottom: 1%;
      left: 8%;
    }
  }
}
