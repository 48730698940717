@import './styles/common/common.scss';
@import './styles/common/extensions.scss';
@import './styles/common/mixins.scss';
@import './styles/common/variables.scss';
@import './styles/common/popup.scss';

.App {
  color: $colorPrimary;

  .Header {
    width: 100%;
    padding: 5% 5%;
    background: white;
  }

  .aside {
    display: none;
  }

  .main {
    position: absolute;
    height: 60%;
    width: 100%;
    padding: 0 5%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 992px) {
  .App {
    .aside {
      display: flex;
      position: fixed;
      padding-left: 5%;
      width: 25%;
      min-height: 60%;
      border-right: 1px solid $colorBorder;
    }

    .main {
      width: 70%;
      left: 25%;
      margin: 0;
    }

    .Header {
      #Setting {
        display: none;
      }
    }
  }
}
