@import '../App.scss';

.Nav {
  flex-direction: column;
}

@media only screen and (min-width: 992px) and (min-height: 600px) {
  .Nav {
    .LinkWithIcon {
      margin-left: 10%;
    }
  }
}
