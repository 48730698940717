@import './mixins.scss';

.popup {
  z-index: 1;
  &-container {
    position: fixed;
    background: rgba(108, 91, 122, 0.5);
    @include position(0);
  }

  position: fixed;
  background: white;
  overflow-y: scroll;
  padding: 5%;
  @include position(5%, 10%, auto, 10%);
  @extend %y-centered;
  flex-direction: column;
  -webkit-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.16);
}

@media only screen and (max-height: 600px) {
  .popup {
    bottom: 5%;
  }
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  .popup {
    top: 10%;
    left: 20%;
    right: 20%;
  }
}

@media only screen and (min-width: 1200px) {
  .popup {
    left: 30%;
    right: 30%;
  }
}
