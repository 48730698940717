@import '../App.scss';

.DeckDetail {
  .link {
    display: none;
  }

  .name {
    @extend %y-centered;
    margin: 5% 0 2% 5%;
    font-size: 150%;
    input {
      max-width: 30%;
      font-weight: bolder;
      color: $colorSecondary;
    }
  }

  .DeckNav {
    display: flex;
    @extend %y-centered;
    justify-content: space-between;
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.16);
    height: 60px;

    p {
      margin-left: 5%;
      color: $colorSecondary;
    }

    .links {
      display: flex;
      .LinkWithIcon {
        span {
          display: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (min-height: 600px) {
  .DeckDetail {
    .name {
      display: none;
    }

    .link {
      display: block;
      margin: 0 0 3% 5%;
      font-size: 150%;
      color: $colorTextSecondary;
    }

    .DeckNav {
      display: block;
      height: 60%;
      position: fixed;
      padding-left: 5%;
      width: 25%;
      box-shadow: none;
      border-right: 1px solid $colorBorder;

      p {
        display: none;
      }

      .links {
        display: flex;
        flex-direction: column;
        .LinkWithIcon {
          span {
            display: block;
          }
        }
      }
    }
  }
}
