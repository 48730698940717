@import '../App.scss';

.LinkWithIcon {
  display: grid;
  grid-template-columns: 10% 10% 80%;
  width: 60%;
  padding: 5px 15px 5px 0;

  .icon {
    @extend %xy-centered;
  }

  span {
    grid-column-start: 3;
  }
}

@media only screen and (min-width: 992px) {
  .LinkWithIcon {
    grid-template-columns: 25% 70%;
    width: 100%;

    span {
      grid-column-start: 2;
    }
  }
}
