@import './variables.scss';

.x-centered {
  display: flex;
  justify-content: center;
}

.y-centered {
  display: flex;
  align-items: center;
}

.xy-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  border: none;
  background: transparent;
  &:focus {
    outline: 0;
  }
  color: white;
}

a,
input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  resize: none;
  border: none;
  background: inherit;
}

a {
  color: $colorPrimary;
  font-weight: bold;

  &:hover {
    color: $colorSecondary;
    text-decoration: none;
  }
}

.a-active {
  color: $colorSecondary;
}

.vertical-line {
  border-left: 1px solid $colorBorder;
  min-height: 75vh;
}

.horizontal-line {
  border-bottom: 1px solid rgba($colorBorder, 0.5);
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
button,
textarea {
  margin: 0;
  padding: 0;
}
