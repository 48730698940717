@import '../App.scss';

.DeckTest {
  .CardButton {
    width: 60%;
  }
}

@media only screen and (min-width: 575px) {
  .DeckTest {
    .CardButton {
      width: 40%;
    }
  }
}

@media only screen and (min-width: 992px) {
  .DeckTest {
    .CardButton {
      width: 60%;
      height: 220px;
    }
  }
}
